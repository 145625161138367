import React from 'react'
import { ThemeProvider } from 'styled-components'
import BootstrapProvider from '@bootstrap-styled/provider'
import { theme, bootstrapTheme } from '@theme'

export const wrapRootElement = ({ element }) => (
  <BootstrapProvider theme={bootstrapTheme} injectGlobal={true}>
    <ThemeProvider theme={theme}>
      {element}
    </ThemeProvider>
  </BootstrapProvider>
)
