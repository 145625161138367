import { createGlobalStyle, css } from 'styled-components'
import {
  colors,
  Colors,
  transitionDurations,
  TransitionDurations,
  fontFamilyBrand,
  borderRadius,
  BorderRadius,
} from './variables'
import { transition, Transition } from './transition'
import {
  fontFace,
  fontPaths,
  fontWeight,
  FontWeight,
  fontSizes,
  FontSizes,
  rfs,
  RFS,
} from './typography'
import { breakpoints, mediaQuery, Breakpoints, MediaQuery } from './breakpoints'
import { spacer, Spacer } from './spacer'
import { rem, Rem } from './rem'

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: Colors
    fontWeight: FontWeight
    fontSizes: FontSizes
    transitionDurations: TransitionDurations
    breakpoints: Breakpoints
    spacer: Spacer
    mediaQuery: MediaQuery
    transition: Transition
    rem: Rem
    rfs: RFS
    borderRadius: BorderRadius
  }
}

export const theme = {
  colors,
  transitionDurations,
  transition,
  fontFace,
  fontFamilyBrand,
  fontPaths,
  fontWeight,
  fontSizes,
  breakpoints,
  mediaQuery,
  spacer,
  rem,
  rfs,
  borderRadius,
}

export const bootstrapTheme: any = {
  $primary: colors.primary,
  '$font-family-base': theme.fontFamilyBrand,
  '$font-size-base': theme.fontSizes.base,
  '$font-weight-base': theme.fontWeight.normal,
  '$body-bg': theme.colors.white,
  '$body-color': theme.colors.gray700,
  // '$link-color': theme.colors.black,
  // '$link-hover-color': theme.colors.primary,
  // '$link-decoration': 'underline',
  '$enable-rounded': true,
  '$border-radius-sm': theme.borderRadius.small,
  '$border-radius-lg': theme.borderRadius.large,
  '$headings-line-height': 1.2,
  '$headings-font-weight': theme.fontWeight.normal,
  '$h1-font-size': 50,
  '$h2-font-size': 38,
  '$h3-font-size': 32,
  '$h4-font-size': 24,
  '$h5-font-size': 22,
  '$btn-secondary-border': theme.colors.white,
  '$btn-primary-color': theme.colors.gray700,
  '$table-border-color': theme.colors.primary,
  '$label-margin-bottom': theme.spacer(0.25),
  '$input-color': theme.colors.gray700,
  '$alert-danger-text': theme.colors.red,
  '$alert-success-bg': theme.colors.primary,
  '$alert-success-border': theme.colors.primaryHover,
  '$alert-success-text': theme.colors.gray700,
}

// used in SSR
export const GlobalAppStyle = createGlobalStyle`
  ${theme.fontFace(
    theme.fontFamilyBrand,
    theme.fontPaths.BarlowRegular,
    theme.fontWeight.normal,
    'normal',
    'woff2',
  )};
  ${theme.fontFace(
    theme.fontFamilyBrand,
    theme.fontPaths.BarlowMedium,
    theme.fontWeight.medium,
    'normal',
    'woff2',
  )};
  ${theme.fontFace(
    theme.fontFamilyBrand,
    theme.fontPaths.BarlowBold,
    theme.fontWeight.bold,
    'normal',
    'woff2',
  )};

  ::selection {
    color: ${theme.colors.gray700};
    background: ${theme.colors.primary};
    text-shadow: none;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    -webkit-text-size-adjust: 100%;
  }

  body {
    font-family: ${theme.fontFamilyBrand}, sans-serif;
    font-size: ${theme.fontSizes.base};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    line-height: 1.5;
  }

  a {
    ${theme.transition('color')};
    color: ${theme.colors.gray700};
    text-decoration: underline;

    &:focus,
    &:hover {
      color: ${theme.colors.primary};

      svg {
        fill: currentColor;
      }

      .alert-success & {
        color: currentColor;
        text-decoration: none;
      }
    }
  }

  b,
  strong {
    font-weight: ${theme.fontWeight.medium};
  }

  html {
    .overflow-hidden {
      overflow: hidden;
    }
  }

  figure {
    margin-left: 0;
    margin-right: 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    margin: 0;
    font-style: italic;
  }

  address {
    font-style: normal;
  }

  svg {
    ${theme.transition('fill')};
  }

  textarea {
    resize: vertical;
    min-height: 100px;
  }

  iframe {
    display: block;
    max-width: 100%;
    border: 0;
  }

  .wp-block-image {
    .alignleft {
      float: left;
      margin-top: 0;
      margin-right: ${theme.spacer(2)};
    }
    .alignright {
      float: right;
      margin-top: 0;
      margin-left: ${theme.spacer(2)};
    }
  }

  /* global style for headings */
  ${Array.from(new Array(6)).reduce((acc, val, idx) => {
    return css`
      ${acc}
      h${idx + 1} {
        ${({ theme }) => theme.rfs(bootstrapTheme[`$h${idx + 1}-font-size`])}
        font-weight: ${bootstrapTheme['$headings-font-weight']};
        line-height: ${bootstrapTheme['$headings-line-height']};
        margin-top: 0;
        margin-bottom: ${theme.spacer(0.5)};
      }
    `
  }, '')}

  .ReactModal__Body--open {
    overflow: hidden; /* prevent scrolling of content behing the modal window */
  }

  .ReactModal__Overlay {
    opacity: 0;
    ${theme.transition('opacity')}

    &--after-open {
      opacity: 1;
    }

    &--before-close {
      opacity: 0;
    }
  }

  .ReactModal__Content {
    transform: translate(-50%, -40px);
    ${theme.transition('transform')}

    &--after-open {
      transform: translate(-50%, 0);
    }

    &--before-close {
      transform: translate(-50%, -40px);
    }
  }

  #nprogress {
    .bar {
      height: 5px !important;
    }
  }

  // WP gallery
  .wp-block-gallery {
    clear: both;
    margin-block: 2rem;
  }

  .blocks-gallery-grid {
    display: flex;
    flex-wrap: wrap;
    margin-right: calc(30px / -2);
    margin-left: calc(30px / -2);
    padding-left: 0;
    list-style: none;
  }

  .blocks-gallery-item {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: calc(30px / 2);
    padding-left: calc(30px / 2);

    .columns-2 & {
      flex: 0 0 50%;
      max-width: 50%;
    }

    .columns-3 & {
      flex: 0 0 33.33%;
      max-width: 33.33%;
    }

    a {
      display: flex;
    }

    img {
      display: block;
      object-fit: cover;
    }

    figure {
      display: flex;
      height: 100%;
      margin-block: 0;
    }
  }

`
