export type Breakpoints = {
  XSMALL: number
  SMALL: number
  MEDIUM: number
  LARGE: number
}

export enum breakpoints {
  XSMALL = 576,
  SMALL = 768,
  MEDIUM = 992,
  LARGE = 1200,
}

export type MediaQuery = (breakpoint: breakpoints, minMax: 'min' | 'max') => string

export const mediaQuery: MediaQuery = (breakpoint, minMax = 'min'): string => {
  const bp = minMax === 'max' ? breakpoint - 1 : breakpoint

  return `@media screen and (${minMax}-width: ${bp}px)`
}
