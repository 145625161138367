import { css, FlattenSimpleInterpolation } from 'styled-components'

import BarlowRegular from '../fonts/Barlow-Regular.woff2'
import BarlowMedium from '../fonts/Barlow-Medium.woff2'
import BarlowBold from '../fonts/Barlow-Bold.woff2'
import { rem } from './rem'
import { mediaQuery, breakpoints } from './breakpoints'

export type FontWeight = {
  normal: number
  medium: number
  bold: number
}

export type FontSizes = {
  xxxlarge: string
  xxlarge: string
  xlarge: string
  large: string
  base: string
  small: string
  xsmall: string
  xxsmall: string
}

export const fontFace = (
  name: string,
  path: string,
  fontWeight: number,
  fontStyle: string,
  format: 'woff' | 'woff2' = 'woff2',
): FlattenSimpleInterpolation => css`
  @font-face {
    font-family: '${name}';
    src: url('${path}') format('${format}');
    font-weight: ${fontWeight};
    font-style: ${fontStyle};
    font-display: swap;
  }
`

export const fontPaths = {
  BarlowRegular,
  BarlowMedium,
  BarlowBold,
}

export const fontWeight: FontWeight = {
  normal: 400,
  medium: 500,
  bold: 700,
}

export const fontSizes: FontSizes = {
  xxxlarge: rem(28),
  xxlarge: rem(24),
  xlarge: rem(22),
  large: rem(20),
  base: rem(18),
  small: rem(16),
  xsmall: rem(14),
  xxsmall: rem(12),
}

export type RFS = (px: number) => string

export const rfs = (px: number): string => `
  font-size: ${rem(px)};
  ${mediaQuery(breakpoints.LARGE, 'max')} {
    font-size: calc(${rem(px * 0.6)} + 1.5vw);
  }`
