import { css, FlattenSimpleInterpolation } from 'styled-components'
import { transitionDurations } from './variables'

export type Transition = (
  property: string | string[],
  duration?: string,
  timingFunction?: string,
) => FlattenSimpleInterpolation

export const transition: Transition = (
  property,
  duration = transitionDurations.default,
  timingFunction = 'ease',
): FlattenSimpleInterpolation => {
  const properties = !Array.isArray(property) ? [property] : property

  const transitions = properties.reduce((acc, prop, idx, arr) => {
    return `${acc}${prop} ${duration} ${timingFunction}${
      arr.length > 1 && idx !== arr.length - 1 ? ',' : ''
    }`
  }, '')

  return css`
    transition: ${transitions};
  `
}
