export type Colors = {
  black: string
  white: string
  primary: string
  primaryHover: string
  secondary: string
  gray000: string
  gray100: string
  gray200: string
  gray300: string
  gray400: string
  gray500: string
  gray600: string
  gray700: string
  red: string
}

export const colors: Colors = {
  black: '#000000',
  white: '#ffffff',
  primary: '#d5b66b',
  primaryHover: '#c79e39',
  secondary: '#000000',

  gray000: '#fafafa',
  gray100: '#f1f1f1',
  gray200: '#ebebeb',
  gray300: '#b0b0b0',
  gray400: '#7f7f7f',
  gray500: '#494949',
  gray600: '#383838',
  gray700: '#222222',

  red: '#d90000',
}

export type TransitionDurations = {
  default: string
  defaultNumber: number
  sal: number
}

export const transitionDurations = {
  default: '0.2s',
  defaultNumber: 200,
  sal: 800,
}

export const fontFamilyBrand = 'Barlow'

export type BorderRadius = {
  small: string
  large: string
}

export const borderRadius: BorderRadius = {
  small: '0.25rem',
  large: '0.5rem',
}
