// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cenik-tsx": () => import("./../src/pages/cenik.tsx" /* webpackChunkName: "component---src-pages-cenik-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-nase-prace-tsx": () => import("./../src/pages/nase-prace.tsx" /* webpackChunkName: "component---src-pages-nase-prace-tsx" */),
  "component---src-pages-o-me-tsx": () => import("./../src/pages/o-me.tsx" /* webpackChunkName: "component---src-pages-o-me-tsx" */),
  "component---src-pages-ochrana-osobnich-udaju-tsx": () => import("./../src/pages/ochrana-osobnich-udaju.tsx" /* webpackChunkName: "component---src-pages-ochrana-osobnich-udaju-tsx" */),
  "component---src-pages-otazky-a-odpovedi-tsx": () => import("./../src/pages/otazky-a-odpovedi.tsx" /* webpackChunkName: "component---src-pages-otazky-a-odpovedi-tsx" */)
}

